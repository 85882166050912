import './main.scss';

function getHeaderSize() {
	const header = document.getElementById("gemino-header");
	if(header) {
		return header.getBoundingClientRect().height;
	}
	return null
}

function adaptRightColumn() {
	const height = getHeaderSize();

	if(height) {
		let elements = document.getElementsByClassName("gemino-sticky-top-120");
		for (let i = 0; i < elements.length; i++) {
			elements[i].style['top'] = height + 15;
		}
	}
}

function fetchAttendees(block, url, start, length) {
	block.innerHTML = '<div class="gemino-loader"></div>';

	fetch(`${url}?${new URLSearchParams({start: start, length: length})}`, {
		method: 'GET'}).then(response => {
		if(response.status !== 200) {
			block.innerHTML = '';
			let errorBlock = document.getElementById('gemino-attendees-error');
			errorBlock.classList.add("display");
		} else {
			return response.json()
		}
	}).then(data => {
			block.innerHTML = '';
			for (let attendee of data) {
				block.innerHTML += `<tr><td>${attendee.last_name} ${attendee.first_name}</td></tr>`
			}
	});
}

function setUpAttendeesList() {

	const attendees_block = document.getElementById('gemino-attendees-list');
	const url = attendees_block.dataset.url;
	let start = attendees_block.dataset.start;
	const length = attendees_block.dataset.length;

	const prev = document.getElementById('prev');
	const next = document.getElementById('next');

	prev.addEventListener("click", () => {
		fetchAttendees(attendees_block, url, start, length);
		start = parseInt(start) - parseInt(length);
	});

	next.addEventListener('click', () => {
		fetchAttendees(attendees_block, url, start, length);
		start = parseInt(start) + parseInt(length);
	});

	fetchAttendees(attendees_block, url, start, length)
}

function initiateModal() {
	const body = document.querySelector("body");

	const template = document.getElementById("template-form-contact-modal");

	const clone = document.importNode(template.content, true);

	body.appendChild(clone);

	const modal = document.getElementById("form-contact-modal");
	const openButton = document.getElementById("open-form-contact-modal");
	const close = document.getElementById("close-form-contact-modal");
	const form = document.getElementById("contact-form");

	openButton.onclick = () => modal.style.display = "block";

	close.onclick = () => modal.style.display = "none";

	// When the user clicks anywhere outside of the modal, close it
    window.addEventListener('click', (event) => {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    });

	form.onsubmit = (e) => {
		e.preventDefault();
		let obj = {};

		for(let i = 0 ; i < form.elements.length ; i++) {
			let item = form.elements.item(i);

			// Exclude elements that are not inputs like submit button
			if (item.name !== "") {
				obj[item.name] = item.value;
			}
		}

		fetch(form.dataset.action, {
			method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
			body: JSON.stringify(obj)}
		).then((res) => {
            console.log("succes :", res.json());
            modal.style.display = "none";
            }
		).catch((response) => console.log("error :", response.json()));
	};
}

// when content is loaded
document.addEventListener("DOMContentLoaded", function() {
	initiateCTA();
	setTimeout(adaptRightColumn, 1000);
	if(document.getElementById('gemino-attendees')) {
		setUpAttendeesList();
	}
	if(document.getElementById('open-form-contact-modal')) {
		initiateModal()
	}
});

function initiateCTA() {
	const classname = document.getElementById("gemino-btn-buy");
	if (classname) {
		classname.addEventListener('click', (event) => scrollToTicket(event), false);
	}
}

const resizeObserver = new ResizeObserver(() => {
	adaptRightColumn();
});

// observe any resize of body
resizeObserver.observe(document.querySelector('body'));

window.addEventListener("message", (event) => {
	// Listen for Willy to be ready
	const data = event.data;
	if(data && data.type === "WZ_READY") {
		refreshCarousels();
	}
}, false);

function refreshCarousels() {
	// image carousel
	const carousels = document.querySelectorAll(".gemino-carousel");

	carousels.forEach(carousel => {
		const buttonPrev = carousel.querySelector(".gemino-prev");
		const buttonNext = carousel.querySelector(".gemino-next");

		buttonPrev.removeEventListener('click', changeSlide);
		buttonNext.removeEventListener('click', changeSlide);

		buttonPrev.addEventListener('click', () => changeSlide(-1));
		buttonNext.addEventListener('click', () => changeSlide(1));

		let slideIndex = 0;

		refreshSlides(slideIndex);

		function changeSlide(increment) {
			let slides = carousel.querySelectorAll(".gemino-slides");
			// modify the state
			slideIndex += increment;
			// if current index is bigger than slides length we come back to the first slide
			if (slideIndex >= slides.length) {
				slideIndex = 0;
			}
			if (slideIndex < 0) {
				slideIndex = slides.length - 1;
			}
			refreshSlides();
		}

		function refreshSlides() {
			let i;
			let slides = carousel.querySelectorAll(".gemino-slides");

			for (i = 0; i < slides.length; i++) {
				slides[i].style.display = "none";
				slides[i].classList.remove("gemino-active");
			}

			slides[slideIndex].style.display = "block";
		}
	});
}

function checkHeaderImageSize() {
	const img = document.getElementById("gemino-img-banner")
	const imgContainer = document.querySelector(".gemino-img-container")
	const width = img.width;
	const height = img.height;
	const containerWidth = imgContainer.offsetWidth;
	const containerHeight = imgContainer.offsetHeight;
	if (width < containerWidth || height < containerHeight) {
		imgContainer.classList.add('banner-too-small')
	}
}

document.addEventListener("load", function() {
	refreshCarousels();
	checkHeaderImageSize();
});

const body = document.querySelector("body");
const config = { attributes: true, childList: true, subtree: true };

const callback = (mutationsList) => {
    if (mutationsList.some(({ type }) => type === 'childList')) {
		refreshCarousels(); 
	} 
};

const observer = new MutationObserver(callback);
observer.observe(body, config);

/*function elementInViewport(el) {
	if (el) {
		let top = el.offsetTop;
		let left = el.offsetLeft;
		let width = el.offsetWidth;
		let height = el.offsetHeight;

		while(el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
			left += el.offsetLeft;
		}

		return (
			top < (window.pageYOffset + window.innerHeight) &&
			left < (window.pageXOffset + window.innerWidth) &&
			(top + height) > window.pageYOffset &&
			(left + width) > window.pageXOffset
		);
	} else {
		return true;
	}
}*/


function scrollToTicket(event) {
	event.preventDefault();
    document.getElementById('gemino-ticketing-container').scrollIntoView();
}


const openEls = document.querySelectorAll("[data-open]");
const closeEls = document.querySelectorAll("[data-close]");
const isVisible = "gemino-is-visible";

for (const el of openEls) {
	el.addEventListener("click", function() {
		const modalId = this.dataset.open;
		document.getElementById(modalId).classList.add(isVisible);
		document.getElementById("gemino-header").style['z-index'] = 0;

	});
}

function removeZIndex() {
	setTimeout(() => {
		document.getElementById("gemino-header").style['z-index'] = 20;
	}, 500)
}

for (const el of closeEls) {
	el.addEventListener("click", function() {
		this.parentElement.parentElement.parentElement.classList.remove(isVisible);
		removeZIndex();
	});
}

document.addEventListener("click", e => {
	if (e.target == document.querySelector(".gemino-modal.gemino-is-visible")) {
		document.querySelector(".gemino-modal.gemino-is-visible").classList.remove(isVisible);
		removeZIndex();
	}
});

document.addEventListener("keyup", e => {
	// if we press the ESC
	if (e.key == "Escape" && document.querySelector(".gemino-modal.gemino-is-visible")) {
		document.querySelector(".gemino-modal.gemino-is-visible").classList.remove(isVisible);
		removeZIndex();
	}
});

// Detect when header is sticky
if(window.innerWidth >= 650 ){
    const geminoHeader = document.querySelector(".gemino-sticky");
    const geminoRow = document.querySelector(".gemino-row");
    const dummyElement = document.createElement("div");
    const geminoHeaderHeight = geminoHeader.offsetHeight;

    // Set the height of the dummy element to match the header's height
    // This is to prevent the page from "jumping" when the header becomes sticky
    dummyElement.style.height = `${geminoHeaderHeight}px`;
    // Insert the dummy element after the header
    geminoHeader.after(dummyElement);


    window.addEventListener("scroll", () => {
        // Check if the header is at the top of the viewport
        const isGeminoAtTop = geminoHeader.getBoundingClientRect().top < 1;
        dummyElement.style.display = isGeminoAtTop ? "block" : "none";
        geminoHeader.classList.toggle("is-pinned", isGeminoAtTop);
        // Add margin to the row to prevent the page from "jumping" when the header becomes sticky
        geminoRow.style.marginTop = isGeminoAtTop ? `-${geminoHeaderHeight}px` : "0";
    });
}

// Masked fixed CTA when geminoTicketingContainer or footer is in view
const geminoFooter = document.querySelector(".gemino-footer");
const geminoTicketingContainer = document.querySelector("#gemino-ticketing-container");
const geminoCTA = document.querySelector(".gemino-data-event-cta");

const isInViewport = element => element.getBoundingClientRect().top < window.innerHeight;

const isLeavingViewport = element => element.getBoundingClientRect().bottom + 101 < window.innerHeight;

window.addEventListener("scroll", () => {
    const footerInView = isInViewport(geminoFooter);
    const ticketingContainerInView = isInViewport(geminoTicketingContainer);
    const footerLeavingView = isLeavingViewport(geminoFooter);
    const ticketingContainerLeavingView = isLeavingViewport(geminoTicketingContainer);

    geminoCTA.classList.toggle(
		"is-masked", 
		(footerInView && !footerLeavingView) || 
		(ticketingContainerInView && !ticketingContainerLeavingView)
	);
});
